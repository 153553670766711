import { StaticImageData } from 'next/image'
import Image from 'next/image'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import archidekt from '@/assets/ads/archidekt.png'
import cardGamer from '@/assets/ads/cardGamer.png'
import ch from '@/assets/ads/ch.png'
import csb from '@/assets/ads/csb.png'
import dragonShield from '@/assets/ads/dragonShield.png'
import edhrecap from '@/assets/ads/edhrecap.png'
import edhrecast from '@/assets/ads/edhrecast.png'
import edhrecPatreon from '@/assets/ads/edhrecPatreon.png'
import fabrec from '@/assets/ads/fabrec.png'
import scg1 from '@/assets/ads/scg1.png'
import scg2 from '@/assets/ads/scg2.png'
import spellifyDark1 from '@/assets/ads/spellifyDark1.png'
import spellifyDark2 from '@/assets/ads/spellifyDark2.png'
import spellifyLight1 from '@/assets/ads/spellifyLight1.png'
import spellifyLight2 from '@/assets/ads/spellifyLight2.png'
import OutboundLink from '@/components/OutboundLink/OutboundLink'
import { useAppSelector } from '@/store/hooks'
import useDirectories from '@/utils/useDirectories'

import styles from './Ad.module.scss'

type AdConfigItem = {
  alt: string
  disableUtm?: boolean
  src: StaticImageData | { lg: StaticImageData; sm: StaticImageData }
  url: string | { lg: string; sm: string }
  urlConfigs?: { src: StaticImageData; subpage: string; url: string }[]
}

const config: { [key: string]: AdConfigItem } = {
  archidekt: { alt: 'Archidekt', src: archidekt, url: 'https://archidekt.com/landing' },
  cardgamer: { alt: 'Card Gamer', src: cardGamer, url: 'cardgamer.com' },
  ch: { alt: "Commander's Herald", src: ch, url: 'commandersherald.com' },
  csb: { alt: 'Commander Spellbook', src: csb, url: 'commanderspellbook.com' },
  dragonShield: {
    alt: 'Dragon Shield',
    src: dragonShield,
    url: 'https://mtg.dragonshield.com/download-app?utm_source=Website&utm_medium=LandingPage&utm_campaign=EDHREC',
  },
  edhrecap: { alt: 'EDHRecap', src: edhrecap, url: 'edhrec.com/newsletter' },
  edhrecast: { alt: 'EDHRECast', src: edhrecast, url: 'https://www.youtube.com/edhrecast' },
  edhrecPatreon: { alt: 'EDHREC Patreon', src: edhrecPatreon, url: 'patreon.com/edhrec' },
  fabrec: { alt: 'FABREC', src: fabrec, url: 'fabrec.gg' },
  /* heavyPlay: {
    alt: 'Heavy Play',
    src: heavyPlay5Color,
    url: 'heavyplay.com',
    urlConfigs: [
      { src: heavyPlayWhite, subpage: 'w', url: 'https://heavyplay.com/collections/white' },
      { src: heavyPlayBlue, subpage: 'u', url: 'https://heavyplay.com/collections/blue' },
      { src: heavyPlayBlack, subpage: 'b', url: 'https://heavyplay.com/collections/black' },
      { src: heavyPlayRed, subpage: 'r', url: 'https://heavyplay.com/collections/red' },
      { src: heavyPlayGreen, subpage: 'g', url: 'https://heavyplay.com/collections/green' },
      { src: heavyPlayColorless, subpage: 'colorless', url: 'https://heavyplay.com/collections/colorless' },
      { src: heavyPlayAzorius, subpage: 'wu', url: 'https://heavyplay.com/collections/azorius' },
      { src: heavyPlayDimir, subpage: 'ub', url: 'https://heavyplay.com/collections/dimir' },
      { src: heavyPlayRakdos, subpage: 'br', url: 'https://heavyplay.com/collections/rakdos' },
      { src: heavyPlayGruul, subpage: 'rg', url: 'https://heavyplay.com/collections/gruul' },
      { src: heavyPlaySelesnya, subpage: 'gw', url: 'https://heavyplay.com/collections/selesnya' },
      { src: heavyPlayOrzhov, subpage: 'wb', url: 'https://heavyplay.com/collections/orzhov' },
      { src: heavyPlayIzzet, subpage: 'ur', url: 'https://heavyplay.com/collections/izzet' },
      { src: heavyPlayGolgari, subpage: 'bg', url: 'https://heavyplay.com/collections/golgari' },
      { src: heavyPlayBoros, subpage: 'rw', url: 'https://heavyplay.com/collections/boros' },
      { src: heavyPlaySimic, subpage: 'gu', url: 'https://heavyplay.com/collections/simic' },
      { src: heavyPlayEsper, subpage: 'wub', url: 'https://heavyplay.com/collections/esper' },
      { src: heavyPlayGrixis, subpage: 'ubr', url: 'https://heavyplay.com/collections/grixis' },
      { src: heavyPlayJund, subpage: 'brg', url: 'https://heavyplay.com/collections/jund' },
      { src: heavyPlayNaya, subpage: 'rgw', url: 'https://heavyplay.com/collections/naya' },
      { src: heavyPlayBant, subpage: 'gwu', url: 'https://heavyplay.com/collections/bant' },
      { src: heavyPlayAbzan, subpage: 'wbg', url: 'https://heavyplay.com/collections/abzan' },
      { src: heavyPlayJeskai, subpage: 'urw', url: 'https://heavyplay.com/collections/jeskai' },
      { src: heavyPlaySultai, subpage: 'bgu', url: 'https://heavyplay.com/collections/sultai' },
      { src: heavyPlayMardu, subpage: 'rwb', url: 'https://heavyplay.com/collections/mardu' },
      { src: heavyPlayTemur, subpage: 'gur', url: 'https://heavyplay.com/collections/temur' },
      { src: heavyPlay5Color, subpage: 'wubrg', url: 'https://heavyplay.com/collections/wubrg' },
    ],
  }, */
  placeholder: {
    alt: 'An entry with lg and sm must exist to satisfy TypeScript',
    src: { lg: archidekt, sm: archidekt },
    url: { lg: '', sm: '' },
  },
  scg1: { alt: 'Star City Games', src: scg1, url: 'https://starcitygames.com/?aff=6' },
  scg2: { alt: 'Star City Games', src: scg2, url: 'https://starcitygames.com/?aff=6' },
  spellifyDark1: {
    alt: 'Spellify',
    src: spellifyDark1,
    url: 'https://commandersherald.com/games/spellify?utm_campaign=dark1',
  },
  spellifyDark2: {
    alt: 'Spellify',
    src: spellifyDark2,
    url: 'https://commandersherald.com/games/spellify?utm_campaign=dark2',
  },
  spellifyLight1: {
    alt: 'Spellify',
    src: spellifyLight1,
    url: 'https://commandersherald.com/games/spellify?utm_campaign=light1',
  },
  spellifyLight2: {
    alt: 'Spellify',
    src: spellifyLight2,
    url: 'https://commandersherald.com/games/spellify?utm_campaign=light2',
  },
}

type Props = {
  id: keyof typeof config
}

const Ad = ({ id }: Props) => {
  const displayName = useAppSelector((state) => state.user.displayName)
  const { subpage } = useDirectories()

  useEffect(() => {
    ReactGA.event({
      action: 'Load',
      category: 'Ad',
      label: id as string,
      nonInteraction: true,
    })
    if (displayName) {
      ReactGA.event({
        action: 'Load-LoggedIn',
        category: 'Ad',
        label: id as string,
        nonInteraction: true,
      })
    }
  }, [id])

  if (!Object.keys(config).includes(id as string)) {
    return null
  }

  const { alt, disableUtm, src, url, urlConfigs } = config[id]

  if (urlConfigs && subpage) {
    const subpageAd = urlConfigs.find((urlConfig) => urlConfig.subpage === subpage)
    if (subpageAd) {
      return (
        <OutboundLink url={subpageAd.url} utm_medium='leaderboard'>
          <img alt={alt} className={styles.ad} src={('src' in subpageAd.src && subpageAd.src.src) || ''} />
        </OutboundLink>
      )
    }
  }

  if (typeof url === 'string') {
    return (
      <OutboundLink disableUtm={disableUtm} url={url} utm_medium='leaderboard'>
        <img alt={alt} className={styles.ad} src={('src' in src && src.src) || ''} />
      </OutboundLink>
    )
  } else {
    return (
      <>
        <OutboundLink className='d-block d-sm-none' url={url.sm} utm_medium='leaderboard'>
          <Image alt={alt} className={styles.ad} src={('sm' in src && src.sm.src) || ''} />
        </OutboundLink>
        <OutboundLink className='d-none d-sm-block' url={url.lg} utm_medium='leaderboard'>
          <Image alt={alt} className={styles.ad} src={('lg' in src && src.lg.src) || ''} />
        </OutboundLink>
      </>
    )
  }
}

export default Ad
